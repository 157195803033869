import React, { useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import { message,  } from 'antd';
import { Grid, Card, Text, Pagination, Input, Row, Button} from "@nextui-org/react";
import {SearchAlt} from '@styled-icons/boxicons-regular/SearchAlt'
import Icon, { GithubOutlined } from '@ant-design/icons';
import axios from "axios";


export default function Blog_list() {

    // 相当于 componentDidMount 和 componentDidUpdate:
    const [a, setA] = useState(1);
    const startNavigate = useNavigate();
    let [current_page, setCurrentPage] = useState(1);   //当前页面，初始化为1
    let [total_pages, setTotalPages] = useState(10);   //总页面数量，初始化为10
    let [blogs, setBlogs] = useState([]);     //当前页面展示的博客，使用数组
    let [blog_num, setBlogNum] = useState(5);    //当前页面展示博客的数量，初始5
    let [search_title, setSearchTitle] = useState("");

    const onSearchBlog = () =>{
        // message.success(search_title );
        if(search_title === ""){
            message.success("请输入搜索的内容!")
            axios.get('/blogs?currentPage=' + current_page, {
                // params: params 
            }).then(res => {
                console.log(res.data.data);
                setCurrentPage(res.data.data.current);
                setTotalPages(res.data.data.pages);
                setBlogs(res.data.data.records);
                setBlogNum(res.data.data.size);
    
                console.log(blogs);
                console.log(total_pages);
    
            });
            return ;
        }
        axios.get('/blogtitle/' + search_title + '/?currentPage=' + current_page, {
            // params: params 
        }).then(res => {
            console.log(res.data.data);
            if(res.data.data.total===0){
                message.error("无法搜索到相应的文章！");
                setCurrentPage(1);
                setTotalPages(1);
                setBlogs(res.data.data.records);
                setBlogNum(res.data.data.size);

            }else{
                setCurrentPage(res.data.data.current);
                setTotalPages(res.data.data.pages);
                setBlogs(res.data.data.records);
                setBlogNum(res.data.data.size);
                console.log(blogs);
            }
            

        });
    }

    const BlogCard = (props) => {
        const idx = Number(props.idx);
        const blog_id = props.id;
        const blog_time = props.time;

        const css_mt = { mt: "1rem" };
        const css_none = {};

        const error = () => {
            message.error('This is an error message' );
        };

        const onCardPress = () => {
            // message.success('This is an success message' + blog_id);
            startNavigate("/blog/" + blog_id);
        }

        return (<Card isPressable isHoverable  onPress={onCardPress} css={idx === 0 ? css_none : css_mt}  >
            <Card.Body css={{ display: "flex", justifyContent: "space-between", py: "$10",marginTop:"0.5rem", height:"4.5rem", paddingBottom:"0vh", paddingTop:"0.5rem", }}>
                <Row justify='space-between'>
                    <Text b>{props.title}</Text>
                    <Text size="sm" css={{marginLeft:"1vw",}} >
                        {blog_time}
                    </Text>
                </Row>
                
            </Card.Body>
        </Card>);
    };

    useEffect(() => {     //  模拟componentDidMount  首次渲染
        // console.log('首次渲染');
        console.log('currentpage改变时渲染');
        axios.get('/blogs?currentPage=' + current_page, {
            // params: params 
        }).then(res => {
            console.log(res.data.data);
            setCurrentPage(res.data.data.current);
            setTotalPages(res.data.data.pages);
            setBlogs(res.data.data.records);
            setBlogNum(res.data.data.size);

            console.log(blogs);
            console.log(total_pages);

        });

    }, [])    // 空数组必须写
    useEffect(() => {     //  模拟componentDidMount  首次渲染
        console.log('currentpage改变时渲染');
        axios.get('/blogs?currentPage=' + current_page, {
            // params: params 
        }).then(res => {
            console.log(res.data.data);
            setCurrentPage(res.data.data.current);
            setTotalPages(res.data.data.pages);
            setBlogs(res.data.data.records);
            setBlogNum(res.data.data.size);
            console.log(blogs);
            // _this.blogs = res.data.data.records
            // _this.currentPage = res.data.data.currentPage
            // _this.total = res.data.data.total
            // _this.pageSize = res.data.data.size
        });
    }, [current_page])    // 空数组必须写
    useEffect(() => {     //  模拟componentDidMount  首次渲染
        console.log('total_pages改变后渲染');
        console.log(total_pages);

    }, [total_pages])    // 空数组必须写

    useEffect(() => {   
        // 使用浏览器的 API 更新页面标题
        // document.title = `You clicked ${count} times`;
        
    });
    
    return (
        <Grid.Container gap={0} justify="center" css={{}}>

            <Grid sm={1.5} xs={0} css={{ height:"50vh", display: "inline-block", position:"fixed", left:"0", mt:"5rem", pl:"2vw"}}>
                     
            </Grid>
            <Grid  sm={1.5} xs={0} ></Grid>
            <Grid sm={9} xs={12} direction="column">
            
                    <Text h1 weight="blod" css={{textAlign:"center",mt:"3vh"}}> 所有文章</Text>
                    <Text h5  css={{textAlign:"center",}}> </Text>
          
                    {/* <div dangerouslySetInnerHTML={{__html:blog_html}} /> */}
                    <Card isHoverable   >
                        <Card.Body css={{ }}>
                            <Row justify='space-between'>
                                <Input css={{h:"100%", w:"100%",ml:"1vw", mr:"1vw"}} placeholder="输入搜索的文章名" onChange={(event) => {setSearchTitle(event.target.value)}}/> 
                                <Button css={{mr:"1vw"}} auto color="success"  icon={<Icon component={SearchAlt} style={{fontSize: '2em',color:"white", margin:"auto"}}/>} onClick={onSearchBlog} />
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card css={{mt:"2vh", $$cardColor: '$colors$blue400',}} >
                        <Card.Body>

                            <div   >
                                {blogs.map((blog, index) => {
                                    return <BlogCard
                                        idx={index} id={blog.id} title={blog.title} description={blog.description} time={blog.created} >
                                        </BlogCard>
                                })}
                            </div>

                            <div id="pageination" css={{ jc: "center", background: "$pink800", color: '$blue800' }}>
                                <Pagination total={total_pages} page={current_page} onChange={(page) => { console.log(page + " dd"); setCurrentPage(page) }} color="gradient" shadow css={{ mt: "4vh" }} />
                            </div>
                        </Card.Body>
                    </Card>
            </Grid>
            <Grid sm={1.5} xs={0}>
                {/* <MockItem text="1 of 3" /> */}
            </Grid>
        </Grid.Container>
    );
}
