import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { message } from 'antd';
import Cookies from 'js-cookie';
import { Navbar, Link, Text, Avatar, Dropdown, styled, Container, Modal, Input, Row, Checkbox, Button } from "@nextui-org/react";
import './page_with_nav.css'
import axios from "axios";
import { Password, Mail, LockIcon, UnLockIcon } from '../login_modal/index.js';


export default function Page_with_nav() {
    const collapseItems = [  //TODO 移动端适配
        ["首页", "/"],
        ["归档", "/blogs"],
        ["测试", "/test"],
        ["友链", "/friends"],
        ["关于", "/about"],
    ];
    const location = useLocation();
    const startNavigate = useNavigate();
    const [login_token, setLoginToken] = useState(null);
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");

    const [modal_visible, setModalVisible] = React.useState(false);
    const loginHandler = () => setModalVisible(true);
    const submitLoginHandler = () => {
        setModalVisible(false);
        const loginForm = {
            username: email,
            password: password,
        };
        axios.post('/login', loginForm).then(res => {
            const jwt = res.headers['authorization'] //得到从后端传来的jwt
            const userInfo = res.data.data
            console.log(res);
            message.success(res);
            // TODO  JWT存储  暂时使用cookie存储boolean 变量
            setLoginToken(jwt);

            Cookies.set('jwt_token', jwt, {
                expires: 7,   //7天后移除
                sameSite: 'strict'
            });
            //保存token等信息到本地共享
            // _this.$store.commit("SET_TOKEN", jwt)
            // _this.$store.commit("SET_USERINFO", userInfo)

            //获取信息test
            // console.log(_this.$store.getters.getUser)

            // _this.$router.push("/blogs")
        })
    };
    const closeLoginHandler = () => {
        setModalVisible(false);
        // console.log("cancel closed");
    };

    useEffect(() => {
        setLoginToken(Cookies.get('jwt_token'))
    }, [])

    const onMenuItemClicked = (key) => {
        message.success(key)
        if (key === "logout") {
            Cookies.remove('jwt_token', {
                path: ''
            })
            setLoginToken(null);
        } else if (key == "new_blog") {
            startNavigate("/newblog");
        }

    }

    return (
        <Layout css={{
            width: "100vw",
            minHeight: "100vh",

        }}>
            <Navbar isBordered variant="sticky" maxWidth="fluid" css={{ w: "100vw", h: "4rem" }}>
                <Navbar.Toggle showIn="xs" />
                <Navbar.Brand
                    css={{
                        w: "15%",
                        "@xs": {

                        },
                    }}
                >
                    {/* <AcmeLogo /> */}
                    <Text b color="inherit" >
                        PokeStar's Blog
                    </Text>
                </Navbar.Brand>
                <Navbar.Content
                    css={{
                        jc: "flex-end",
                        "@xs": {

                        },
                    }}
                >
                    <Navbar.Content
                        enableCursorHighlight
                        activeColor="primary"
                        hideIn="xs"
                        variant="highlight-rounded"

                    >
                        <Navbar.Link isActive={location.pathname === "/"} href="/">首页</Navbar.Link>
                        <Navbar.Link isActive={location.pathname === "/blogs"} href="/blogs">归档</Navbar.Link>
                        <Navbar.Link isActive={location.pathname === "/test"} href="/test">简历</Navbar.Link>
                        <Navbar.Link isActive={location.pathname === "/friends"} href="/friends">友链</Navbar.Link>
                        <Navbar.Link isActive={location.pathname === "/about"} href="/about">关于</Navbar.Link>
                    </Navbar.Content>

                    {login_token == null ?
                        <Navbar.Item>
                            <Button css={{ jc: "flex-end", }} auto flat rounded onClick={loginHandler} >
                                登录
                            </Button>
                        </Navbar.Item>
                        :
                        <Navbar.Content css={{ jc: "flex-end", }} >

                            <Dropdown placement="bottom-right">
                                <Navbar.Item>
                                    <Dropdown.Trigger>
                                        <Avatar
                                            bordered
                                            as="button"
                                            color="secondary"
                                            size="md"
                                            src="/logo.jpg"
                                        // src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
                                        />
                                    </Dropdown.Trigger>
                                </Navbar.Item>
                                <Dropdown.Menu
                                    aria-label="User menu actions"
                                    color="secondary"
                                    onAction={onMenuItemClicked}
                                >
                                    <Dropdown.Item key="profile" css={{ height: "$18" }}>
                                        <Text b color="inherit" css={{ d: "flex" }}>
                                            当前是管理员模式！
                                        </Text>
                                        <Text b color="inherit" css={{ d: "flex" }}>
                                            1219521375@qq.com
                                        </Text>
                                    </Dropdown.Item>
                                    <Dropdown.Item key="new_blog" withDivider>
                                        发布新博客
                                    </Dropdown.Item>
                                    <Dropdown.Item key="my_resume">我的简历</Dropdown.Item>

                                    <Dropdown.Item key="system">System</Dropdown.Item>
                                    <Dropdown.Item key="configurations">Configurations</Dropdown.Item>
                                    <Dropdown.Item key="logout" withDivider color="error">
                                        Log Out
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Content>

                    }
                    <Modal
                        closeButton
                        blur
                        aria-labelledby="modal-title"
                        preventClose
                        open={modal_visible}
                        onClose={closeLoginHandler}
                    >
                        <Modal.Header>
                            <Text id="modal-title" size={18}>
                                Welcome to <br />
                                <Text b size={18}>
                                    PokeStar's Blog
                                </Text>
                            </Text>
                        </Modal.Header>
                        <Modal.Body>
                            <Input
                                clearable
                                bordered
                                fullWidth
                                color="primary"
                                size="lg"
                                placeholder="Email"
                                contentLeft={<Mail fill="currentColor" />}
                                onChange={(e) => { setEmail(e.target.value) }}
                            />
                            <Input.Password
                                clearable
                                bordered
                                fullWidth
                                color="primary"
                                size="lg"
                                placeholder="Password"
                                contentLeft={<Password fill="currentColor" />}
                                onChange={(e) => { setPassword(e.target.value) }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button auto flat color="error" onClick={closeLoginHandler}>
                                Close
                            </Button>
                            <Button auto onClick={submitLoginHandler}>
                                Sign in
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </Navbar.Content>

                <Navbar.Collapse>
                    {/* TODO */}
                    {collapseItems.map((item, index) => (
                        <Navbar.CollapseItem
                            key={item}
                            activeColor="secondary"
                            css={{
                                color: index === collapseItems.length - 1 ? "$error" : "",
                            }}
                            isActive={index === 2}
                        >
                            <Link
                                color="inherit"
                                css={{
                                    minWidth: "100%",
                                }}
                                href={item[1]}
                            >
                                {item[0]}
                            </Link>
                        </Navbar.CollapseItem>
                    ))}
                </Navbar.Collapse>
            </Navbar>
            <Container fluid css={{ mt: "2vh", width: "100vw" }}>

                <div id="main" css={{ width: "100vw", minHeight:"100vh" }}>
                    <Outlet css={{ width: "100vw", minHeight:"100vh" }} >
                    </Outlet>
                </div>


                <Footer ></Footer>


            </Container>


            {/* <Content>        </Content> */}

        </Layout>

    );
}

const Layout = ({ children }) => (
    <Box
        css={{
            width: "100vw",
            minHeight:"100vh",
        }}
    >
        {children}
    </Box>
);
const Footer = () => (
    <Box id="footer"   >
        <div className="footer_text">
            <Text>版权所有 @ 2022 PokeStar.保留所有权利</Text>
            <Text>备案号 </Text>
        </div>
        <div className="footer_text">
            <Text> Designed by PokeStar, using React and NextUI as front </Text>
        </div>
        
    </Box>
);

const Box = styled("div", {
    boxSizing: "border-box",
});
