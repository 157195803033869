import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import About from "./components/about";
import Blog_list from "./components/blog_list";
import Current_Blog from "./components/current_blog";
import Home from "./components/home";
import Page_with_nav from "./components/Page_with_nav";
import './utils/axios.js';
import './App.css';
import NewBlog from "./components/newblog";

export default function App() {
  return (
    <div className="app">     
      {/* 子路由嵌套在父路由中，子路由在<Outlet>中渲染，详见<Layout/>. 路径为'/'时，<Outlet>中为<Home/> */}
      <Routes>
        {/* 主页 */}
        <Route path="/" element={<Page_with_nav />}>  
          <Route index element={<Home />} />
          <Route path="/blogs" element={<Blog_list />}/>
          <Route path="/newblog" element={<NewBlog />}/>
          <Route path="/blog/:id" element={<Current_Blog/>} />
          <Route path="/friends" element={<Friends />} />
          <Route path="/about" element={<About />} />
          <Route path="/test" element={<Test />} />
          <Route path="/resume" element={<Resume />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}


function Test() {
  return (
    <div>
      <h2>Test</h2>
    </div>
  );
}

function Friends() {
  return (
    <div>
      <h2>Friends</h2>
    </div>
  );
}

function Resume() {
  return (
    <div>
      <h2>wo de jian li</h2>
    </div>
  );
}


function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
