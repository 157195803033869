import React, { useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import { message,  } from 'antd';
import { Grid, Card, Col, Text, Pagination, Image, Popover, Button } from "@nextui-org/react";
import "./home.css";
import axios from "axios";
import styled from 'styled-components';
import {Qq} from '@styled-icons/remix-line/Qq';
import {Wechat, Github} from '@styled-icons/remix-fill';
import Icon, { GithubOutlined } from '@ant-design/icons';


export default function Home() {


    const startNavigate = useNavigate();
    let [current_page, setCurrentPage] = useState(1);   //当前页面，初始化为1
    let [total_pages, setTotalPages] = useState(10);   //总页面数量，初始化为10
    let [blogs, setBlogs] = useState([]);     //当前页面展示的博客，使用数组
    let [blog_num, setBlogNum] = useState(5);    //当前页面展示博客的数量，初始5


    // console.log("test");
    // console.log(blogs);
    const BlogCard = (props) => {
        const idx = Number(props.idx);
        const blog_id = props.id;
        const blog_time = props.time;

        const css_mt = { mt: "1rem" };
        const css_none = {};

        const error = () => {
            message.error('This is an error message' + blogs);
        };

        const onCardPress = () => {
            // message.success('This is an success message' + blog_id);
            startNavigate("/blog/" + blog_id);
        }

        return (<Card isPressable isHoverable  onPress={onCardPress} css={idx === 0 ? css_none : css_mt}  >
            <Card.Header css={{paddingBottom:"0",}}>
                <Text b>{props.title}</Text>
            </Card.Header>
            {/* <Card.Divider /> */}
            <Card.Body css={{  py: "$10",marginTop:"0.5rem", height:"4.5rem", paddingBottom:"0vh", paddingTop:"0.5rem", borderTop: "$borderWeights$light solid $gray500", }}>
                <Text css={{textOverflow: "ellipsis", display:"-webkit-box", WebkitBoxOrient:'vertical', overflow:'hidden',WebkitLineClamp:'2'}}>
                    {props.description}
                </Text>
            </Card.Body>
            {/* <Card.Divider /> */}
            <Card.Footer isBlurred css={{jc:"space-between", h:"2rem", textAlign: "center",alignItems: "center", paddingTop:"1.5rem", borderTop: "$borderWeights$light solid $gray400", }}>
                    
             
                    <Text size="sm" css={{marginLeft:"1vw",}} >
                        {blog_time}
                    </Text>
                    <div id="labels">
                       <Text size="sm" weight="bold" css={{
                            textGradient: "45deg, $blue600 -20%, $pink600 50%", paddingRight: "1vw",
                        }} >标签1</Text>
                        <Text size="sm" weight="bold" css={{
                            textGradient: "45deg, $blue600 -20%, $pink600 50%", paddingRight: "1vw",
                        }} >标签2</Text>
                    </div>
                    
            </Card.Footer>
        </Card>);
    };

    useEffect(() => {     //  模拟componentDidMount  首次渲染
        // console.log('首次渲染');
        console.log('currentpage改变时渲染');
        axios.get('/blogs?currentPage=' + current_page, {
            // params: params 
        }).then(res => {
            console.log(res.data.data);
            setCurrentPage(res.data.data.current);
            setTotalPages(res.data.data.pages);
            setBlogs(res.data.data.records);
            setBlogNum(res.data.data.size);

            console.log(blogs);
            console.log(total_pages);

        });

    }, [])    // 空数组必须写
    useEffect(() => {     //  模拟componentDidMount  首次渲染
        console.log('currentpage改变时渲染');
        axios.get('/blogs?currentPage=' + current_page, {
            // params: params 
        }).then(res => {
            console.log(res.data.data);
            setCurrentPage(res.data.data.current);
            setTotalPages(res.data.data.pages);
            setBlogs(res.data.data.records);
            setBlogNum(res.data.data.size);
            console.log(blogs);
            // _this.blogs = res.data.data.records
            // _this.currentPage = res.data.data.currentPage
            // _this.total = res.data.data.total
            // _this.pageSize = res.data.data.size
        });
    }, [current_page])    // 空数组必须写
    useEffect(() => {     //  模拟componentDidMount  首次渲染
        console.log('total_pages改变后渲染');
        console.log(total_pages);

    }, [total_pages])    // 空数组必须写
    useEffect(() => {

    });

    return (
        <Grid.Container gap={2} justify="start" id="homeview">
            <Grid xs={12} sm={9} direction="column" justify='start' alignContent='start'  >
                {/* 展示博客文章列表 */}
                <div   >
                    {blogs.map((blog, index) => {
                        return <BlogCard
                            idx={index} id={blog.id} title={blog.title} description={blog.description} time={blog.created} >

                        </BlogCard>
                    })}
                </div>

                <div id="pageination" css={{ jc: "center", background: "$pink800", color: '$blue800' }}>
                    <Pagination total={total_pages} page={current_page} onChange={(page) => { console.log(page + " dd"); setCurrentPage(page) }} color="gradient" shadow css={{ mt: "4vh" }} />
                </div>

            </Grid>
            <Grid xs={0} sm={3} direction="column" justify='start' hideIn='xl' alignContent='start'>
                {/* 展示博客信息，个人信息 */}
                <div>
                    <Card css={{ w: "100%", }}>
                        <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
                            <Col>
                                <Text size={12} weight="bold" transform="uppercase" color="#ffffffAA">
                                    Welcome
                                </Text>
                                <Text size={25} weight="bold" color="white">
                                    欢迎来到 PokeStar 的博客！
                                </Text>
                            </Col>
                        </Card.Header>
                        <Card.Image
                            src="/logo.jpg"
                            width="100%"
                            height="100%"
                            objectFit="cover"
                            alt="Card image background"
                        />
                    </Card>

                    <Card css={{ h: "$20", $$cardColor: '$colors$blue400', mt: "3vh" }}>
                        <Card.Body >
                            <div id="account">
                                <Popover>
                                    <Popover.Trigger>
                                        <Icon component={Qq} style={{ fontSize:"3em", color:"white", textAlign:"center"}}></Icon>
                                    </Popover.Trigger>
                                    <Popover.Content>
                                        <Image
                                            src="/pokestar_QQ.jpg"
                                            width={200}
                                            height={250}
                                            objectFit="cover"
                                            alt="Card image background"
                                        />
                                    </Popover.Content>
                                </Popover>
                                <Popover >
                                    <Popover.Trigger>
                                        <Icon component={Wechat} style={{fontSize: '3em',color:"white",}}></Icon>
                                    </Popover.Trigger>
                                    <Popover.Content>
                                        <Image
                                            src="/pokestar_wechat.jpg"
                                            width={200}
                                            height={200}
                                            objectFit="cover"
                                            alt="Card image background"
                                        />
                                    </Popover.Content>
                                </Popover>
                                <a href={"http://github.com/1219521375"} target='_blank' rel='noreferrer'>
                                    <Icon component={Github} style={{fontSize: '3em',color:"white", margin:"auto"}}></Icon>
                                </a>
                                
                            </div>
                        </Card.Body>
                    </Card>

                    <Card css={{  $$cardColor: '$colors$blue400', mt: "3vh" }}>
                        <Card.Body css={{ width:"20em",wordWrap:"break-word", wordBreak:"break-all",}}>
                            <div>
                                <Text span="true" size={15} color="white" css={{ mr: "1rem" }}>
                                    多模态学习
                                </Text>
                                <Text span="true" size={15} color="white" css={{ mr: "1rem" }}>
                                    DeepLearning
                                </Text>
                                <Text span="true" size={15} color="white" css={{ mr: "1rem" }}>
                                    React
                                </Text>
                                <Text span="true" size={15} color="white" css={{ mr: "1rem" }}>
                                    Springboot
                                </Text>
                                <Text span="true" size={15} color="white" css={{ mr: "1rem" }}>
                                    linux
                                </Text>
                                <Text span="true" size={15} color="white" css={{ mr: "1rem" }}>
                                    后端
                                </Text>
                            </div>
                            
                        </Card.Body>
                    </Card>

                </div>
            </Grid>

        </Grid.Container>
    );
}
