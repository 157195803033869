import axios from 'axios'
// import router from './router'
// import store from './store'
import { message, Space } from 'antd';

axios.defaults.baseURL = "http://81.68.90.170:8081"

//拦截器 前置拦截 发起请求时配置
axios.interceptors.request.use(config => {
    console.log("前置拦截")
    return config
})

//后置拦截
axios.interceptors.response.use(response => {
    let res = response.data;
    // console.log("后置拦截")
    // console.log(res)
    console.log("后置拦截")

    if (res.code === 200) {
        return response
    } else {
        // alert("")
        //捕获并返回message
        message.error('错了哦，这是一条错误消息');
        //阻止进入后部逻辑
        return Promise.reject(response.data.msg)
    }
},
    error => {
        console.log(error)
        if (error.response == null){
            message.error("无法从服务器获取回应! " + error.message);
            return Promise.reject(error);
        }
        if (error.response.data) {
            error.message = error.response.data.msg
        }

        if (error.response.status === 401) {
            //未认证 跳转登录
            // store.commit("REMOVE_INFO")
            // router.push("/login")
        }
        message.error("! " + error.message);
        //阻止进入后部逻辑
        return Promise.reject(error)
    }
)