import React, { useState, useEffect } from "react";
import { Card, Grid, Text, Link } from "@nextui-org/react";
import { createFromIconfontCN } from "@ant-design/icons";
import './about.css'

const IconFont = createFromIconfontCN({
    // scriptUrl:'//at.alicdn.com/t/c/font_3683222_n8uvglsdq8f.js'
    scriptUrl:'./iconfont.js'
});
export default function About() {

    // 相当于 componentDidMount 和 componentDidUpdate:
    const [a, setA] = useState(1);
    useEffect(() => {

        // 使用浏览器的 API 更新页面标题
        // document.title = `You clicked ${count} times`;

    });

    return (
        <div>
            <Card css={{ mw: "90vw", marginTop: "1rem", p: "$6" }}>
                <Card.Header>
                    <img
                        // alt="nextui logo"
                        src="/logo.jpg"
                        width="60px"
                        height="60px"
                    />
                    <Grid.Container css={{ pl: "$6" }}>
                        <Grid xs={14}>
                            <Text h1 css={{ lineHeight: "$xs" }}>
                                PokeStar
                            </Text>
                        </Grid>
                        <Grid xs={10}>
                            <Text h3 css={{ color: "$accents8" }}>blog.pokestar.wang</Text>
                        </Grid>
                    </Grid.Container>
                </Card.Header>
                <Card.Body css={{ py: "$2" }}>
                    <Text h3>
                        HDU 硕士在读，目前研究方向是多模态学习，正在基于react和springboot学习前后端的知识，博客内容包括日常开发学习遇到的一些问题记录，学习笔记，以及一些个人随笔等。
                    </Text>
                    <Text h2 b >联系方式</Text>
                    {/* from阿里巴巴矢量图标库 */}
                    <ul css={{fontSize:"10rem"}}>
                        <li css={{fontSize:"10rem"}}> <IconFont id="icon_qq" type="icon-QQ"></IconFont> 1219521375</li>
                    </ul>
                    <ul css={{fontSize:"10rem"}}>
                        <li css={{fontSize:"10rem"}}> <IconFont id="icon_github" type="icon-GitHub"></IconFont> https://github.com/1219521375</li>
                    </ul>
                </Card.Body>
                <Card.Footer>
                    <Link
                        icon
                        color="primary"
                        target="_blank"
                        href="https://github.com/nextui-org/nextui"
                    >
                        我的在线简历
                    </Link>
                </Card.Footer>
            </Card>
            <Card css={{ mw: "90vw", marginTop: "1rem", p: "$6" }}>
                <Card.Header>
                    <Grid.Container css={{ pl: "$6" }}>
                        <Grid xs={12}>
                            <Text span="true" h2 b css={{ lineHeight: "$xs" }}>
                                关于这个博客
                            </Text>
                           
                        </Grid>
                        <Grid xs={12}>
                            {/* <span css={{ color: "$accents8" }}>blog.pokestar.wang</span> */}
                            <Text h3>
                                前端使用了react编写界面，后端使用springboot编写后端逻辑，shiro进行权限管理。
                            </Text>
                        </Grid>
                    </Grid.Container>
                </Card.Header>
                {/* <Card.Body css={{ py: "$2" }}>
                    <Text>
                        使用了react开发了前端博客页面，使用了springboot作为后端服务，并部署于腾讯云服务器上。
                    </Text>
                    <Text>
                        大部分代码已经在github上开源，前端入门选手，欢迎交流指正。
                    </Text>
                </Card.Body> */}
                <Card.Footer>
                </Card.Footer>
            </Card>
        </div>

    );
}

