import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Card, Text, css} from "@nextui-org/react";
import axios from "axios";
import MdEditor from 'md-editor-rt';
import './current_Blog.css';
import 'md-editor-rt/lib/style.css';



export default function Current_Blog() {
    
    const editorId = 'my-editor';
    const params = useParams();
    const blogid = params.id;
    const [blog_title, setBlogTitle] = useState("文章标题");
    const [blog_content, setBlogContent] = useState("文章内容");
    const [blog_created, setBlogCreated] = useState("2022-11-9");
    const [blog_description, setBlogDescription] = useState("文章简介");
    const [blog_html, setBlogHTML] = useState(null);

    const MockItem = ({ text }) => {
        return (
            <MdEditor modelValue={blog_content} editorId={editorId} htmlPreview preview={false} previewOnly={true} />
        );
    };

    // 相当于 componentDidMount 和 componentDidUpdate:
    useEffect(() => {     //  模拟componentDidMount  首次渲染
        console.log('首次渲染');
        axios.get('/blog/' + blogid, {
            // params: params 
        }).then(res => {
            console.log(res.data.data);
            setBlogTitle(res.data.data.title);
            setBlogContent(res.data.data.content);
            setBlogCreated(res.data.data.created);
            setBlogDescription(res.data.data.description);
        });
    }, [])    // 空数组必须写

    return (
    
        <Grid.Container gap={0} justify="center" css={{}}>

            <Grid sm={1.5} xs={0} css={{ height:"50vh", display: "inline-block", position:"fixed", left:"0", mt:"5rem", pl:"2vw"}}>
                     <MdEditor.MdCatalog editorId={editorId} scrollElement={document.documentElement}/>
               
            </Grid>
            <Grid  sm={1.5} xs={0} ></Grid>
            <Grid sm={9} xs={12} direction="column">
            
                    <Text h1 weight="blod" css={{textAlign:"center",}}> {blog_title}</Text>
                    <Text h5  css={{textAlign:"center",}}> 创建时间： {blog_created}</Text>
          
                    {/* <div dangerouslySetInnerHTML={{__html:blog_html}} /> */}
                    
                    <Card >
                        <Card.Body>
                            {/* <Text>Default card. (shadow)</Text> */}
                            {/* <Text h6 size={15} css={{ m: 0 }}> {blog_content}</Text>  editor xiugaiyoubianyikuai */}
                            <MdEditor  modelValue={blog_content} editorId={editorId} previewOnly={true} onHtmlChanged={(h)=>{setBlogHTML(h)}} />
                            
                        </Card.Body>
                    </Card>
                    {/* 
                    <Text h6 size={15} css={{ m: 0 }}> {blog_description}</Text>
                     */}
            </Grid>
            <Grid sm={1.5} xs={0}>
                {/* <MockItem text="1 of 3" /> */}
            </Grid>
        </Grid.Container>

    );
}
