import { useState, useRef } from "react";
const createFileInput = () => {
    const $fileInput = document.createElement("input");
    $fileInput.type = "file";
    $fileInput.accept = ".md";
    return $fileInput;
};
const useTextFileReader = () => {
    const [fileName, setFileName] = useState("");
    const [fileContent, setFileContent] = useState("");
    const [isReading, setIsReading] = useState(false);
    const [error, setError] = useState(null);
    const fileInputRef = useRef(createFileInput());
    const handleFileReader = async (e) => {
        setIsReading(true);
        try {
            const file = e.target.files[0];
            
            const reader = new FileReader();
            reader.onload = function (e) {
                setFileName(file.name.split(".")[0]);
                setFileContent(e.target.result);
                
            };
            reader.readAsText(file);
        }
        catch (error) {
            setError(error);
        }
        finally {
            setIsReading(false);
        }
    };
    fileInputRef.current.onchange = handleFileReader;
    const trigger = () => {
        fileInputRef.current.click();
    };
    //接受时 变量名需一一对应
    return {
        fileName,
        fileContent,
        isReading,
        error,
        trigger,
    };
};
export default useTextFileReader;