import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Card, Text, Input, Button, Row, css } from "@nextui-org/react";
import axios from "axios";
import Cookies from 'js-cookie';
import MdEditor from 'md-editor-rt';
import 'md-editor-rt/lib/style.css';
import './new_blog.css';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import useTextFileReader from "../../utils/file_reader";

export default function NewBlog() {

    const startNavigate = useNavigate();
    const isLogin = Cookies.get("token");
    
    const editorId = "my-editor";
    // const blog_created = getCurrentTime();
    const [blog_content, setBlogContent] = useState('');
    // const [blogid, setBlogId] = useState(null);
    const {
        fileName,
        fileContent,
        isReading,
        error,
        trigger,
    } = useTextFileReader();

    useEffect(() => {     //  模拟componentDidMount  首次渲染
        // setBlogContent(fileContent);
        if (fileName != "") {
            message.success(fileName);
            document.getElementById("input_blog_name").value=fileName;
            setBlogContent(fileContent);
        }
    }, [fileName])    // 空数组必须写

    const onSubmit = () => {
        if(Cookies.get("jwt_token") == null){
            message.error("请先登录！")
        }else{
            const ruleForm = {
                id: '',
                title: document.getElementById("input_blog_name").value,
                description: blog_content,
                content: blog_content,
            };
            axios.post("/blog/edit", ruleForm, {
                headers: {
                    "Authorization": Cookies.get("jwt_token")
                }
            }).then((res) => {
                console.log(res)
                message.success("提交成功！")
                startNavigate("/");
                // _this.$alert('操作成功', '提示', {
                //     confirmButtonText: '确定',
                //     callback: action => {
                //         _this.$router.push("/blogs")
                //     }
                // });
            })
        }
        
    }



    return (
        <Grid.Container gap={0} justify="center" css={{}}>

            <Grid sm={2} xs={0} direction={"column"} css={{ height: "50vh", display: "inline-block", position: "fixed", left: "0", mt: "5rem", pl: "2vw" }}>
                {/* <MockItem text="1 of 2" /> */}
                <Text>目录</Text>
                <MdEditor.MdCatalog editorId={editorId} 
                // scrollElement={document.documentElement}
                // css={{width:"100px",display: "inline-block" }}
                />

            </Grid>
            <Grid sm={2} xs={0} ></Grid>

            <Grid sm={10} xs={12} direction="column">

                {/* <Text h1 weight="blod" css={{ textAlign: "center", }}> blog_title</Text> */}
                <Input
                    id="input_blog_name"
                    css={{ mt: "2rem" }}
                    clearable
                    underlined
                    label="请输入博客名"
                    
                />
                {/* <Text h5 css={{ textAlign: "center", }}> 创建时间： {blog_created}</Text> */}
                {/* <MdEditor modelValue={blog_content} editorId={editorId} previewOnly={true} /> */}
                <Row css={{ mt: "1vh", mb: "2vh", jc: "space-between" }}>

                    <Button shadow bordered rounded ghost color="gradient" auto onClick={trigger} >导入</Button>

                    <Button shadow bordered rounded ghost color="gradient" auto onClick={onSubmit}>提交</Button>
                </Row>
                <Card >
                    <Card.Body>
                        {/* <Text>Default card. (shadow)</Text> */}
                        {/* <Text h6 size={15} css={{ m: 0 }}> {blog_content}</Text> */}
                        <MdEditor modelValue={blog_content} onChange={setBlogContent} editorId={editorId}
                            css={{ h: "100px" }}
                        />
                    </Card.Body>
                </Card>
                {/* 
<Text h6 size={15} css={{ m: 0 }}> {blog_description}</Text>
 */}
            </Grid>


        </Grid.Container>
    );
};

function getCurrentTime() {
    var date = new Date();//当前时间
    var year = date.getFullYear() //返回指定日期的年份
    var month = repair(date.getMonth() + 1);//月
    var day = repair(date.getDate());//日
    var hour = repair(date.getHours());//时
    var minute = repair(date.getMinutes());//分
    var second = repair(date.getSeconds());//秒

    //当前时间 
    var curTime = year + "-" + month + "-" + day
        + " " + hour + ":" + minute + ":" + second;
    return curTime;
}

function repair(i) {
    if (i >= 0 && i <= 9) {
        return "0" + i;
    } else {
        return i;
    }
}